<template>
  <div class="card-container">
    <ContentTitle>{{ tableTitle }}</ContentTitle>
    <Wfilter
      @handleSearch="handleSearch"
      @changeStatus="changeStatus"
      @changeProject="changeProject"
      :filterConfig="filterConfig"
      @print="handlePrint"
      @export="handleExport"
    ></Wfilter>
    <Wtable
      :columns="columns"
      :pagination="pagination"
      :tableData="tableData"
      :showCheck="showCheck"
      @sizeChange="sizeChange"
      @info="openDetailDialog"
      @currentChange="currentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template #use_text="{ row }">
        <span
          :style="{
            color: row.use_text == '使用中' ? 'var(--theme-color)' : '',
          }"
          >{{ row.use_text }}</span
        >
      </template>
      <template #status="{ row }">
        <span :style="{ color: row.status == 1 ? '' : 'var(--theme-color)' }">{{
          row.status_text
        }}</span>
      </template>
      <!-- <div class="total-money">
        费用合计：<span class="primary">266666.26元</span>
      </div> -->
      <!-- <div class="issue-btn">
        <el-button type="primary" plain round @click="handleIssue"
          >批量下发(共{{ selectedCard.length }})条</el-button
        >
      </div> -->
    </Wtable>
    <Wdialog
      ref="detailDialog"
      title="IC卡详情"
      width="50%"
      :showAction="false"
      class="ic-detail"
    >
      <div class="light-bg basic-info" v-if="detailInfo">
        <div class="detail-title">IC卡号：{{ detailInfo.code }}</div>
        <div class="user-name">{{ detailInfo.owner_name }}</div>
        <div>有效期至 {{ detailInfo.end_time }}</div>
      </div>

      <br />
      <div v-if="detailInfo.gate && detailInfo.gate.length" class="light-bg">
        <span class="detail-title">道闸名称</span>
        <div class="gate-info">
          <template v-for="(el, index) in detailInfo.gate" :key="index">
            <div class="gate-item">{{ el.name }}</div>
          </template>
        </div>
      </div>
    </Wdialog>
  </div>
</template>
<script>
import { ref, onMounted, reactive, onUnmounted, computed } from "vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import { print, exportExcel, getBtnAuth } from "@/utils/common.js";
import Wtable from "@/components/wTable/wTable.vue";
import Wdialog from "@/components/wDialog/wDialog.vue";
import Wfilter from "@/components/wFilter/wFilter.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import API from "@/plugins/api.js";
import config from "./cardAdmin.json";

export default {
  components: {
    Wtable,
    Wdialog,
    Wfilter,
    ContentTitle,
  },
  setup() {
    const filterConfig = reactive(config.filterConfig);
    const columns = reactive(config.tableConfig.list);
    const pagination = config.tableConfig.pagination;
    const defaultBtns = reactive(config.tableConfig.btns);
    const showCheck = config.tableConfig.showCheck;
    const keyword = ref("");
    const page = ref(1);
    const projectId = ref("");
    const status = ref(null);
    const tableData = ref([]);
    const tableTitle = ref("IC卡管理");
    const store = useStore();
    const rightBtn = computed(() => store.state.saasConfig.rightBtn); //右侧页面的所有权限信息
    // 获取权限按钮
    columns[columns.length - 1].btns = getBtnAuth(rightBtn.value, defaultBtns);
    function fetchData(parmas) {
      let lastParmas = {
        ...parmas,
        keyword: keyword.value,
        page: page.value,
        project_id: projectId.value,
        limit: pagination.pageSize,
      };
      status.value ? (lastParmas["status"] = status.value) : "";
      API.getIcCard(lastParmas).then((res) => {
        pagination.total = res.total;
        tableData.value = res.data;
      });
    }
    onMounted(() => {
      API.getProjectOptions({}).then((res) => {
        filterConfig.selects[0].mapData = res;
      });
      API.getIcCardStatus({}).then((res) => {
        filterConfig.selects[1].mapData = res;
      });
      fetchData();
    });

    const currentChange = (v) => {
      page.value = v;
      fetchData();
    };
    const sizeChange = (v) => {
      pagination.pageSize = v;
      fetchData();
    };
    const changeProject = (pro) => {
      projectId.value = pro.value;
      fetchData();
    };
    const changeStatus = (pro) => {
      status.value = pro.value;
      fetchData();
    };
    const handleSearch = (kwd) => {
      keyword.value = kwd;
      fetchData();
    };
    const selectedCard = ref([]);
    function handleSelectionChange(data) {
      selectedCard.value = data;
    }
    function handleIssue() {
      if (!selectedCard.value.length) {
        ElMessage.warning({
          message: "请先选择需要下发的ic卡！",
        });
        return false;
      }
    }
    const detailDialog = ref(null);
    const detailInfo = ref({});
    function openDetailDialog(data) {
      API.getIcCardDetail({ id: data.row.id }).then((res) => {
        detailInfo.value = res;
      });
      detailDialog.value.show();
    }
    const propertiesConfig = ref([
      { field: "id", displayName: "ID" },
      { field: "d_name", displayName: "项目" },
      { field: "code", displayName: "卡号" },
      { field: "status_text", displayName: "状态" },
      { field: "use_text", displayName: "使用状态" },
      { field: "owner_name", displayName: "使用人" },
      { field: "owner_tel", displayName: "联系电话" },
    ]);
    function handlePrint() {
      print(tableTitle.value, tableData.value, propertiesConfig.value);
    }
    function handleExport() {
      try {
        exportExcel(tableTitle.value, tableData.value, propertiesConfig.value);
        ElMessage.success({
          message: "导出成功！",
        });
      } catch (e) {
        ElMessage.error({
          message: "导出失败！",
        });
      }
    }
    onUnmounted(() => {
      filterConfig.selects[0].value = "";
      filterConfig.selects[1].value = "";
    });
    return {
      tableData,
      columns,
      pagination,
      currentChange,
      detailDialog,
      openDetailDialog,
      filterConfig,
      changeProject,
      changeStatus,
      handleSearch,
      detailInfo,
      sizeChange,
      showCheck,
      handleSelectionChange,
      selectedCard,
      handleIssue,
      handlePrint,
      handleExport,
      tableTitle,
    };
  },
};
</script>

<style lang='scss'>
.card-container {
  .header-action {
    .el-input__inner {
      background-color: var(--search-bg-color) !important;
    }
    ::-webkit-input-placeholder {
      color: var(--theme-color);
    }
  }

  .ic-detail {
    color: var(--text-color);
    .detail-title {
      font-weight: bold;
      font-size: 16px;
    }
    .basic-info {
      display: flex;
      align-items: center;
      .user-name {
        padding: 0 40px;
      }
    }
    .gate-info {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .gate-item {
        width: 50%;
        line-height: 28px;
      }
    }
    .light-bg {
      background-color: var(--theme-bg-color);
      padding: 10px 20px;
      margin: 0;
      .el-col {
        padding: 0;
      }
    }
  }
}
</style>